// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Snackbar.css */
.snackbar {
    position: fixed;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #bbdefb;
    color: #263238;
    padding: 10px 20px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 9999;
}

.snackbar.show {
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/snackbar.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,eAAe;IACf,aAAa;IACb,SAAS;IACT,2BAA2B;IAC3B,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,wCAAwC;IACxC,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,6CAA6C;AACjD","sourcesContent":["/* Snackbar.css */\n.snackbar {\n    position: fixed;\n    bottom: 150px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: #bbdefb;\n    color: #263238;\n    padding: 10px 20px;\n    border-radius: 4px;\n    opacity: 0;\n    transition: opacity 0.3s, transform 0.3s;\n    z-index: 9999;\n}\n\n.snackbar.show {\n    opacity: 1;\n    transform: translateX(-50%) translateY(-10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
